

const infoContactForm = [
  {
    id: "firstname",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Prénom",
  },
  {
    id: "name",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Nom",
  },
  {
    id: "role",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Rôle"
  },
  {
    id: "email",
    typeForm: "input",
    type: "email",
    required: true,
    placeholder: "Email",
    pattern: "/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/"
  },
  {
    id: "society",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Société"
  },
  {
    id: "phone",
    typeForm: "input",
    type: "phone",
    required: true,
    placeholder: "Téléphone"
  },
  {
    id: "companyType",
    typeForm: "select",
    options: ["Type d'entreprise*", "Entreprise individuelles", "TPE", "PME", "Grandes entreprises"]
  },
  {
    id: "activity",
    typeForm: "select",
    options: [
      "Secteur*",
      "Administration, fonction publique",
      "Agroalimentaire",
      "Artisanat d'art",
      "Associations",
      "Banques / Assurances / Services financiers",
      "Chimie, plastique, conditionnement",
      "Commerce de détail, grande distribution",
      "Communication, marketing, information",
      "Construction, bâtiment, travaux publics",
      "Culture, sports, loisirs",
      "Energie",
      "Enseignement, formation",
      "Environnement, récupération, tri, recyclage, traitement des déchets, matériaux, de l'eau",
      "Equipement, matériel pour activités professionnelles",
      "Fabrication et commerce de gros d'articles destinés à la vente",
      "Gestion, administration des entreprises",
      "Hôtellerie, restauration, tourisme",
      "Immobilier",
      "Industrie textile",
      "Informatique",
      "Ingénieurs d'études et de recherche, chercheurs",
      "Logistique, transports",
      "Matériel électrique, électronique, optique",
      "Mécanique, métallurgie",
      "Minerais, minéraux, sidérurgie",
      "Professions juridiques",
      "Santé, action sociale",
      "Services aux particuliers, collectivités, entreprises"
    ]
  },
  {
    id:"country",
    typeForm: "select",
    options: [
      "Pays*","France", "Belgique", "Suisse", "Allemagne", "Angleterre", "Italie", "Espagne", "Portugal", "Autres"
    ]
  },
  {
    id :"message",
    typeForm: "select",
    options: [
      "Sélectionnez votre message*",
      "Demande d'informations commerciales",
      "Demande d'informations techniques",
      "Demande d'informations juridiques (RGPD...)",
      "Demander à être recontacter par un commercial",
      "Déclarer un incident dans un espace client"

    ]
  },


]

export default infoContactForm
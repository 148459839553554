
import Layout from "../components/decorate/Layout"
import React from "react"
import imgContact from "../images/bateau_contact.png"
import FormTemplate from "../utils/contact/formTemplate"
import infoContactForm from "../utils/contact/contact"
import * as Yup from "yup"

import "../scss/pages/Contact.scss"

const Contact = () => {
  const validationSchema = Yup.object().shape(
    {
      firstname: Yup.string().required("Vous devez renseigner un prénom"),
      name: Yup.string().required("Vous devez renseigner un nom"),
      role: Yup.string().required("Vous devez renseigner votre fonction dans l'entreprise"),
      email: Yup.string().required("Vous devez saisir un email").email("L'email saisi n'est pas correct"),
      society: Yup.string().required("Vous devez renseigner le nom de votre entreprise"),
      phone: Yup.string().matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g, "Veuillez saisir un numéro valide").required("Vous devez renseigner votre numéro de téléphone"),
      companyType: Yup.string().notOneOf(["Type d'entreprise*"], "Vous devez choisir le type de votre entreprise"),
      activity: Yup.string().notOneOf(["Secteur*"], "Vous devez choisir le secteur d'activité de votre entreprise"),
      country: Yup.string().notOneOf(["Pays*"], "Vous devez choisir le pays dans lequel se situe votre entreprise"),
      message: Yup.string().notOneOf(["Sélectionnez votre message*"],"Vous devez choisir la raison de votre message"),

    }
  )

  const dataHat = {
    to: "contact@getcaas.io",
    object: "Quelqu'un cherche à joindre GetCaas",
    templateId: "d-d4485716cba44dcd934af6c05cc6d5db",
    hideWarnings: true
  }
  return (
    <Layout>
      <section className="contact marged">
        <div className="contact__head">
          <div className="contact__head__img">
            <img src={imgContact} title="contactImg" alt="image_contact_getcaas" />
          </div>
          <div className="contact__head__content">
            <div className="contact__head__content__headTitle">
              <h2 className="title-area-homepage">Ecrivez-nous !</h2>
            </div>
            <div className="contact__head__content__headText">
              <p>Vous n'avez pas trouvé de réponse à votre problème ? </p>
              <p>Besoin d'une information complémentaire ? D'une aide ?</p>
            </div>
          </div>
        </div>

        <FormTemplate
          dataHat={dataHat}
          validationSchema={validationSchema}
          listOfElements={infoContactForm}
          firstClassCss="contact"
          msgSuccess="Nous vous remercions pour votre message. Nous reviendrons vers vous rapidement !"
          msgError="Une erreur est survenue lors de l'envoie de votre message."
          labelBtn="Envoyer"
        />
      </section>

    </Layout>
  )
}


export default Contact
